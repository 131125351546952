import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import Hero from "ab-hero";
import { getPage } from "ab-api-queries";
import DynamicContent from "../components/DynamicContent";

const Home = ({ moduloCross }) => {
  const { i18n } = useTranslation("common");

  return (
    <>
      <Hero image="/images/404.png" title="404" i18n={i18n} />
      <DynamicContent
        fieldGroupName={"Page_Contenidoflexible_Modulos_Disfrute"}
        classname="beige"
        moduloCross={moduloCross}
        i18n={i18n}
      />
    </>
  );
};

export async function getStaticProps({ locale }) {
  const data = await getPage("/");

  // // const locale = getLocaleFromContext(context);
  // const redirectURL = getLinkFromTranslation(data?.nodeByUri?.translations, locale);

  // console.log("getStaticProps", locale, context);
  // if (locale != 'en' && redirectURL) {
  //   return {
  //     redirect: {
  //       permanent: false,
  //       destination: redirectURL
  //     }
  //   };
  // }

  const translations = await serverSideTranslations(locale, ["common"]);
  return {
    props: {
      content: data?.nodeByUri?.contenidoFlexible?.modulos || [],
      footer: data?.headerFooter?.footer || null,
      menu: data?.headerFooter?.menu || null,
      translations: data?.nodeByUri?.translations || null,
      language: data?.nodeByUri?.language || null,
      moduloCross: data?.modulosCross || null,
      ...translations,
    }, // will be passed to the page component as props
    revalidate: false,
  };
}

export default Home;
